<template>
  <!----------------------------------------- LABOUR COMPONENT  ------------------------------->
  <div>
    <!--------------------Mechanical ----------------------->
    <div class="mb-4 "
      v-if="selectedTab.type == ItemCategoryTypes.MECH || selectedViewType != viewTypes.tabbedView">
      <quote-builder-other-labour-headings-component name="Mechanical"
        :is-full-width="isFullWidth"
        :is-quote-hour="isQuoteTypeHour"
        :count="mechLength"
        :total-value="mechTotal" />
      <div class="rows">
        <draggable @change="itemMoved($event, ItemCategoryTypes.MECH)"
          v-model="innerValue.others"
          @over.prevent
          @enter.prevent
          :clone="$event => cloneHandler($event, ItemCategoryTypes.MECH)"
          :group="{ name: ItemCategoryTypes.MECH, pull: 'clone', put: true }"
          v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
          handle=".item-drag-handle">
          <quote-builder-other-labour-item v-for="item in mechItems"
            :key="item.id"
            :read-only-view="readOnlyView"
            :vehicle="vehicle"
            :inner-value="innerValue"
            :items-assessments="itemsAssessments"
            :is-quote-type-hour="isQuoteTypeHour"
            :is-full-width="isFullWidth"
            :item="item"
            :edit-item-id="editItemObj.id"
            @deleteItem="item => deleteItem(item)"
            @focusNextRow="id => $emit('focusNextRow', item)"
            @handlePresentAs="itemId => $emit('handlePresentAs', itemId)"
            @itemRowClicked="(value, event, close) => $emit('handleItemRowClick',value, event, close)" />
        </draggable>
      </div>
      <div>
        <div class="button has-text-primary"
          @click="addBlankItem($event, ItemCategoryTypes.MECH)">
          + Add Item
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import QuoteBuilderOtherLabourHeadingsComponent from './QuoteBuilderOtherLabourHeadingsComponent.vue'
import QuoteBuilderOtherLabourItem from './QuoteBuilderOtherLabourItem.vue'
import { ItemCategoryTypes, LabourTimeTypes, PaintGroupTypes, QuotingMethodTypes, QuoteItemStatusTypes } from '@/enums'
import draggable from 'vuedraggable'
import { QuoteItemModel } from '@/classes/viewmodels'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { QuoteTotalsMethodMixin, QuoteAssessmentMixin, QuoteItemValidationMixin, QuoteOtherLabourMixin } from '../../mixins'
import { cloneDeep } from 'lodash'
import deepDiff from 'deep-diff'
import _debounce from 'lodash.debounce'

export default {
  name: 'QuoteBuilderOtherLabourComponent',
  components: {
    QuoteBuilderOtherLabourHeadingsComponent,
    QuoteBuilderOtherLabourItem,
    draggable
  },
  mixins: [QuoteTotalsMethodMixin, QuoteAssessmentMixin, QuoteItemValidationMixin, QuoteOtherLabourMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    isFullWidth: {
      type: Boolean,
      required: true
    },
    editItemObj: {
      type: Object
    },
    selectedTab: {
      type: Object
    },
    selectedViewType: {
      type: String
    },
    viewTypes: {
      type: Object
    },
    vehicle: {
      type: Object
    },
    readOnlyView: {
      type: Boolean
    },
    nextLineNumber: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      innerValue: null
    }
  },
  computed: {
    ItemCategoryTypes() {
      return ItemCategoryTypes
    },
    LabourTimeTypes() {
      return LabourTimeTypes
    },
    PaintGroupTypes() {
      return PaintGroupTypes
    },
    isRowDraggable() {
      return !this.innerValue.readOnly
    },
    QuotingMethodTypes() {
      return QuotingMethodTypes
    },
    isQuoteTypeHour() {
      return this.innerValue.quotingMethod === QuotingMethodTypes.Hour
    },
    mechItems() {
      return this.innerValue.others.filter((i) => i.itemType === ItemCategoryTypes.MECH)
    },
    mechLength() {
      return this.innerValue.others.filter((i) => !i.isDeleted && i.itemType == ItemCategoryTypes.MECH)?.length
    },
    mechTotal() {
      return this.calculateItemCategoryTotal(this.innerValue.quotingMethod, this.innerValue.others, ItemCategoryTypes.MECH)
    },
    isNtar() {
      return (
        this.innerValue.labourType === LabourTimeTypes.NTAR ||
        this.innerValue.labourType === LabourTimeTypes.LTAR ||
        this.innerValue.labourType === LabourTimeTypes.eMTA
      )
    },
    itemsAssessments() {
      return this.getItemsAssessments(cloneDeep(this.innerValue.others), cloneDeep(this.innerValue.quoteAssessments))
    }
  },
  watch: {
    innerValue: {
      handler: _debounce(function (newVal) {
        if (newVal) {
          // compare the new value with value
          if (deepDiff(this.innerValue, this.value)) {
            this.$emit('handleUpdate', cloneDeep(newVal))
          }
        }
      }, 600),
      deep: true
    }
  },
  created() {
    this.innerValue = cloneDeep(this.value)
  },
  mounted() {
    this.$v.entity.others.$touch()
    this.$eventHub.$on('updateOthers', this.updateOthers)
    this.$eventHub.$on('addBlankItemOtherLabour', this.addBlankItem)
    this.$eventHub.$on('deleteOtherLabour', this.deleteItem)
  },
  beforeDestroy() {
    this.$eventHub.$off('updateOthers', this.updateOthers)
    this.$eventHub.$off('addBlankItemOtherLabour', this.addBlankItem)
    this.$eventHub.$off('deleteOtherLabour', this.deleteItem)
  },
  methods: {
    updateOthers() {
      // compare two objects to see if they are the same, sometimes the parent
      // value might be updated by innervalue and we don't want to update the inner value again
      if (deepDiff(this.innerValue, this.value)) {
        this.innerValue = cloneDeep(this.value)
      }
    },
    cloneHandler(item, type) {
      return item
    },
    itemMoved(event, type) {
      // if an item was dragged from one list to another, i.e from RR to RWA, Parts to RR etc.
      if (event?.added) {
        const item = event.added.element
        this.handleItemAdded(item, type, event.added.newIndex)
      }
      let sortNo = Math.min(...this.innerValue.others.map((i) => i.sortNo))
      this.innerValue.others.forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },
    handleItemAdded(item, type, index) {
      const newItem = this.generateItem(item.itemNo, item.itemDesc, item.hourValue, item.rate, type)
      // replace the item with the new item
      this.innerValue.others.splice(index, 1, newItem)
      this.$emit('onItemCopied', newItem)
    },
    addBlankItem(event) {
      // this is to prevent outside click event from firing, which collpases all the items
      event?.stopPropagation()

      const type = ItemCategoryTypes.MECH

      let hour = 0
      let rate = this.isNtar
        ? this.innerValue.shopRate
        : this.getLabourRate(ItemCategoryTypes.MECH, this.innerValue.rates, this.innerValue.shopRate, this.innerValue.quoteId).rate

      const newItem = this.generateItem('', '', hour, rate, type)
      this.innerValue.others.splice(this.innerValue.others.length, 1, newItem)
      this.innerValue.lines = this.nextLineNumber + 1
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })

      this.$nextTick(() => {
        this.$emit('newItemAdded', newItem.quoteItemId)
      })
    },

    generateItem(itemNo, description, hourValue, rate, type) {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, itemNo, description, type)
      newItem.lineNumber = this.nextLineNumber
      newItem.itemNo = itemNo ? itemNo : this.$filters.pad(newItem.lineNumber, 4)
      newItem.hourValue = this.innerValue.quotingMethod === QuotingMethodTypes.Hour ? hourValue : roundAwayFromZero(hourValue * rate)
      newItem.rate = rate
      newItem.dollarValue = roundAwayFromZero(hourValue * rate)
      newItem.opgCode = this.vehicle.paintGroup
      newItem.sortNo = this.innerValue.others.length ? Math.max(...this.innerValue.others.map((i) => i.sortNo)) + 1 : 1
      return newItem
    },
    updateValue(index, item) {
      if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
        item.dollarValue = item.hourValue
      } else {
        item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
        if (this.getLabourRateEx(item).rate === item.rate) {
          item.rev = QuoteItemRevTypes.Normal
        } else {
          item.rev = QuoteItemRevTypes.SpecRate
        }
      }
      // this.updateTotal()
    },

    calculateItemCategoryTotal(quotingMethod, items, itemType) {
      let total = 0

      if (itemType === ItemCategoryTypes.PART) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly)
          .reduce(function (total, item) {
            return roundAwayFromZero(total + item.itemQuantity * item.markupValue)
          }, 0)
      } else if (itemType === ItemCategoryTypes.MISC) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly)
          .reduce(function (total, item) {
            return total + item.value
          }, 0)
      } else if (itemType === ItemCategoryTypes.OPG) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly)
          .reduce(function (total, item) {
            return total + (quotingMethod === QuotingMethodTypes.Dollar ? item.dollarValue : item.hourValue * item.rate)
          }, 0)
      } else if (itemType === ItemCategoryTypes.SUBL) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly)
          .reduce(function (total, item) {
            return total + item.value
          }, 0)
      } else {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly && i.itemType === itemType)
          .reduce(function (total, item) {
            return roundAwayFromZero(total + item.dollarValue)
          }, 0)
      }
      return total
    },
    deleteItem(item) {
      let originalItemStatus = item.itemStatus
      if (item.isNew) {
        const itemIndex = this.innerValue.others
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.others.splice(itemIndex, 1)
          // this.items.splice(itemIndex, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
        item.originalItemStatus = originalItemStatus
      }
      // this.updateTotal()
      this.$snackbar.open({
        message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
        type: 'is-danger',
        position: 'is-bottom',
        actionText: 'Undo',
        onAction: () => {
          if (item.isNew) {
            this.innerValue.others.splice(this.innerValue.others.length, 1, item)
            // this.items.splice(this.items.length, 1, item)
          } else {
            item.deleted = false
            item.isDeleted = false
            item.itemStatus = originalItemStatus
          }
          // this.updateTotal()
        }
      })
    }
  }
}
</script>

<style scoped>
/* Your component's styles go here */
</style>